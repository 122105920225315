@import "common/variables";
// Automatically injected Bower dependencies via wiredep (never manually edit this block)
// bower:scss
@import "../../bower_components/bootstrap/scss/bootstrap.scss";
// endbower
@import "common/global";
@import "components/buttons";
@import "components/comments";
@import "components/forms";
@import "components/grid";
@import "components/wp-classes";
@import "layouts/header";
@import "layouts/sidebar";
@import "layouts/footer";
@import "layouts/pages";
@import "layouts/posts";
@import "layouts/tinymce";


body {
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  color: #2b2b2b;
  overflow-x: hidden;
}


a,
a:hover {
  text-decoration: none;
  color: #00a0cd;
}


#skills.hashlink {
  height: 350px;
  margin-top: -350px;
}


/* Visual composer */
.vc_row-has-fill + .vc_row-full-width + .vc_row > .vc_column_container > .vc_column-inner,
.vc_row-has-fill > .vc_column_container > .vc_column-inner {
  padding-top: 0;
}


.vc_row[data-vc-full-width] {
  overflow: visible;
}


.body.agency-template-default footer .logowpb_button, .wpb_content_element, ul.wpb_thumbnails-fluid > li {
  margin-bottom: 0;
}


.vc_tta-container h2 {
  margin-bottom: 30px;
}


.vc_tta-accordion h4 {
  font-size: 18px;
}


.vc_tta-panel-body {
  background: transparent !important;
  border: none !important;
}


/* Grid */
.single .wrap.container, .single .wrap > .row, .single .main,
.blog .wrap.container, .blog .wrap > .row, .blog .main,
.search .wrap.container, .search .wrap > .row, .search .main,
body.post-type-archive-portfolio .wrap.container {
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
}


/* Header */
header .resp,
header .resp + .search-wrapper {
  display: none;
  visibility: hidden;
  opacity: 0;
}


header {
  position: fixed;
  z-index: 9999;
  width: 100vw;
  
  .logo img.alt {
    display: none;
  }
  
  nav {
    position: relative;
  }
  
  .nav {
    justify-content: center;
  }
  
  li, a {
    color: white;
    padding: 0 13px;
  }
  
  a:hover,
  a.active {
    color: #0076a0 !important;
  }
  
  .left {
    text-align: center;
  }
  
  .right {
    position: relative;
  }
  
  .right:before {
    content: '';
    position: absolute;
    top: 0;
    left: -15px;
    width: calc(100% + 15px);
    height: 1px;
    background: white;
  }
  
  .top {
    font-size: 12px;
    padding: 9px 15px;

    a {
      display: inline-block;
      padding: 7px 20px;
      font-size: 14px;
      color: #fff;
      background-color: #00a0cd;
      border: 1px solid #00a0cd;
      border-radius: 50px;
      transition: all .3s;

      &:hover {
        background-color: #0076a0;
        border-color: #0076a0;
        color: white !important;
      }
    }
  }
  
  .bottom {
    padding-top: 25px;
    border-top: 1px solid white;

    .nav > li:last-child a {
        background-color: #c0d140;
        color: white;
        padding: 10px 25px;
        border-radius: 50px;
    }

  }
  
  li.menu-item > a {
    font-size: 16px;
  }
  
  li.menu-item.menu-item-has-children > ul.sub-menu > li.menu-item.menu-item-has-children:after {
    content: ">";
    position: absolute;
    right: -7px;
    top: 3px;
    font-size: 10px;
    color: #888888;
  }

  .logo img {
    width: 153px;
  }
}


.search-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  display: none;
  
  form button {
    display: none;
  }
  
  .sb-search-input {
    border: none;
    padding: 10px 25px;
    background-color: #efefef;
  }
}


.search-wrapper.active {
  visibility: visible;
  opacity: 1;
}


.search-img.grey {
  display: none;
}


.search-img {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-15px, 25%);
  height: 18px;
}


header.scroll {
  background-color: white;
  
  .search-img.white {
    display: none;
  }
  
  .search-img.grey {
    display: block;
  }
  
  .logo {
    display: inline-block;
    padding: 20px 15px;
  }
  
  .logo img:not(.alt) {
    display: none;
  }
  
  .logo img.alt {
    display: inline-block;
  }
  
  .top {
    background-color: #f7f7f7;
  }
  
  .bottom {
    padding: 42px 15px;
  }
  
  .bottom a,
  .bottom li {
    color: #2b2b2b;
  }
}


header *,
header.scroll * {
  transition: all 0.3s;
}


ul.sub-menu {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  bottom: -15px;
  left: 0 !important;
  transform: translateY(100%);
  transition: all 0.4s;
  background: white;
  padding: 20px 15px 5px 15px;
  margin-left: 0 !important;
  width: 200px;
  list-style: none;
  
  li, a {
    color: #2b2b2b;
  }
  
  li {
    padding: 0 !important;
    margin: 0 0 15px 0 !important;
    background: transparent;
  }
  
  li a {
    width: 100%;
    padding: 0;
  }
}


.menu-item-has-children {
  position: relative;
  cursor: default;
}


.menu-item-has-children:hover > ul.sub-menu,
.menu-item-has-children a:hover + ul.sub-menu {
  visibility: visible;
  opacity: 1;
}


.menu-item-has-children > ul.sub-menu > .menu-item-has-children > ul.sub-menu {
  right: -210px;
  left: inherit !important;
  top: -20px;
  bottom: inherit !important;
  transform: none;
}


#breadcrumbs > span > span > span a {
  pointer-events: none;
}


/* Home (Actu) */
body.blog {
  .item {
    padding: 10px;
    margin-bottom: 30px;
    
    & > div {
      display: block;
      width: 100%;
      height: 100%;
      border: 1px solid #cccccc;
      border-radius: 5px;
      padding: 30px;
      
      .date {
        color: #87888a;
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid #87888a;
        
        img {
          display: inline-block !important;
          width: auto !important;
          margin-right: 5px;
          margin-bottom: 4px;
        }
      }
      
      h4 {
        font-size: 19px;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
      
      .content {
        line-height: 21px;
        max-height: 105px;
        overflow: hidden;
        
        img {
          display: none;
        }
        
        p {
          margin-bottom: 0;
        }
      }
      
      .button {
        margin: 15px 0 20px 0;
      }
    }
  }
}


/* Homeheader */
section.homeheader {
  min-height: 100vh;
  padding-top: 160px;
  padding-bottom: 340px;
  color: white;
  position: relative;
  
  h1 {
    font-size: 62px;
    font-weight: 400;
    line-height: 68px;
    text-indent: 120px;
    margin-bottom: 30px;
  }
  
  .text {
    max-width: 380px;
    margin-left: 180px;
    
    .subtitle {
      display: block;
      position: relative;
      font-size: 21px;
      font-weight: 500;
      line-height: 1.1;
      margin-bottom: 10px;
    }
    
    .subtitle:before {
      content: '';
      position: absolute;
      bottom: 5px;
      left: -140px;
      width: 85px;
      height: 2px;
      background-color: white;
    }
  }

  /* form part */

  .form-wrapper {
    max-width: 380px;
    margin-top: 50px;
    margin-left: 180px;

    .form-wrapper-title {
      display: inline-block;
      position: relative;
      font-size: 16px;
      font-weight: bold;
      color: #c0d140;
      margin-bottom: 30px;

      &:before {
        content: "";
        position: absolute;
        bottom: 5px;
        left: -140px;
        width: 85px;
        height: 2px;
        background-color: #fff;
      }

      svg {
        position: relative;
        top: 3px;
      }
    }

    input:not([type="submit"]) {
      background: transparent;
      color: white;
      border: 1px solid white;
      margin-bottom: 15px;

      &::placeholder { color: white; }
    }

    input[type="submit"] {
      background-color: #c0d140;
      color: #fff;
      padding: 8px 20px;
      border-radius: 50px;
      border: none;
    }
  }
}


/* Title */
.title {
  position: relative;
  font-size: 35px;
  margin-bottom: 30px;
  margin-top: 15px;
}


.title:before {
  content: '';
  position: absolute;
  background-color: #2b2b2b;
}


.title.top:before {
  left: 35px;
  top: -100px;
  width: 2px;
  height: 86px;
}


.title.left:before {
  left: -100px;
  top: 18px;
  width: 86px;
  height: 2px;
}


.title.middle:before {
  left: calc(50% - 1px);
  top: -100px;
  width: 2px;
  height: 86px;
}


.title.none:before {
  display: none;
}


.title.center {
  text-align: center;
}


.title.top.center {
  .title-wrapper, h2 {
    position: relative;
    display: inline-block;
  }
}


.title.top.center:before {
  display: none;
}


.title.top.center .title-wrapper:before {
  content: '';
  position: absolute;
  background-color: #2b2b2b;
  left: 35px;
  top: -100px;
  width: 2px;
  height: 86px;
}


/* Pagetop */
section.pagetop {
  position: relative;
  text-align: center;
  padding: 230px 15px 90px 15px;
  
  h1 {
    position: relative;
    display: inline-block;
    font-size: 62px;
    padding-left: 130px;
    color: white;
  }
  
  h1:before {
    content: '';
    position: absolute;
    top: 38px;
    left: 0;
    width: 86px;
    height: 2px;
    background-color: white;
  }
  
  #breadcrumbs {
    position: absolute;
    bottom: -85px;
    right: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    margin: 0;
    height: 85px;
    color: #00a0cd;
    
    a, .breadcrumb_last {
      color: #87888a;
    }
  }
  
  #breadcrumbs > span span a {
    cursor: default;
    transition: all 0.2s;
    
    &:hover {
      font-weight: 700;
    }
  }
}


/* Button */
.button a {
  display: inline-block;
  padding: 10px 25px;
  font-size: 16px;
  color: white;
  background-color: #00a0cd;
  border: 1px solid #00a0cd;
  border-radius: 50px;
  transition: all 0.2s;
  
  &:hover {
    background-color: #0076a0;
    border-color: #0076a0;
  }
}

.button-blue a {
  background-color: #00a0cd;
  border: 1px solid #00a0cd;
}

.button-green a {
  background-color: #c0d008;
  border: 1px solid #c0d008;
}

.button-arrow a:after {
  content: url(/wp-content/uploads/2019/04/fleche-bouton.svg);
  display: inline-block;
  margin-left: 10px;
  width: 22px;
  height: 8px;
}


.button-phone a:before {
  content: url(/wp-content/uploads/2019/04/phone-bouton.svg);
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 3px;
}


.button-mail a:before {
  content: url(/wp-content/uploads/2019/04/mail-bouton.svg);
  display: inline-block;
  margin-right: 10px;
  position: relative;
  top: 3px;
}


/* Skill */
section.skill {
  position: relative;
  top: -340px;
  padding-top: 150px;
  margin-bottom: -340px;
  z-index: 9;
  
  .item {
    padding: 35px;
    color: white;
    background-color: #0076a0;
    position: relative;
    transition: all 0.3s;
    
    h2 {
      font-size: 19px;
      text-transform: uppercase;
      margin-bottom: 12px;
    }
    
    h3 {
      font-size: 17px;
      font-weight: 700;
      margin-bottom: 10px;
    }
    
    .ask {
      display: none;
      position: absolute;
      bottom: -60px;
      left: 0;
      font-size: 22px;
      font-weight: 700;
      color: #004d70;
    }

    .ask.init-active { display: block; }

    &:hover {
      z-index: 100;
      transform: scale(1.1);
      box-shadow: 5px 5px 5px rgba(0,0,0,0.3);

      a.arrow svg {
        stroke: #c0d140 !important;
      }

      .ask { display: block; }
    }
  }
  
  .item a {
    color: inherit;
  }
  
  .item a.arrow {
    display: inline-block;
    position: absolute;
    bottom: -45px;
    right: 35px;
    width: 60px;
    height: 70px;

    svg { stroke: #004d70; }
  }
  
  .item:nth-child(2) {
    background-color: #004d70;

    a.arrow svg {
      stroke: #3fa1cd;
    }
  }
  
  // .ask {
  //   display: block;
  //   margin-top: 20px;
  //   font-size: 22px;
  //   font-weight: 700;
  //   color: #004d70;
  // }
  
  // .ask:after {
  //   content: '';
  //   display: block;
  //   margin-top: 8px;
  //   width: 90px;
  //   height: 2px;
  //   background: #004d70;
  // }
}


/* Sector */
section.sector {
  .item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .item:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background-color: #bde6f2;
  }
  
  .item:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background-color: #bde6f2;
  }
  
  .item:nth-child(even):after {
    display: none;
  }
  
  .title-wrapper {
    position: relative;
    padding: 70px 15px 60px 15px;
    border-left: 2px solid #bde6f2;
    border-right: 2px solid #bde6f2;
  }
  
  .title-wrapper:before {
    content: '';
    position: absolute;
    z-index: 0;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: #bde6f2;
  }
  
  h2 {
    position: relative;
    z-index: 1;
    font-size: 35px;
    text-indent: 130px;
    letter-spacing: 0.5px;
    color: #00a0cd;
  }
  
  h3 {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 40px;
    font-size: 19px;
    white-space: pre-wrap;
    margin-bottom: 20px;
  }
  
  h3:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -16px;
    transform: translateY(-50%);
    width: 4px;
    height: 40px;
    background-color: #00a0cd;
  }
  
  p {
    white-space: pre-wrap;
    width: 100%;
  }
  
  a {
    display: inline-block;
    margin-bottom: 60px;
    padding: 10px 25px;
    font-size: 16px;
    color: white;
    background-color: #00a0cd;
    border: 1px solid #00a0cd;
    border-radius: 50px;
    transition: all 0.2s;
    
    &:hover {
      background-color: #0076a0;
      border-color: #0076a0;
    }
  }
}


/* Post */
section.post {
  .actus-link a {
    color: #ffffff;
    padding-bottom: 2px;
    border-bottom: solid 2px #ffffff;
  }
  
  h2 {
    position: relative;
    font-size: 35px;
    line-height: 37px;
    color: white;
    margin-top: 150px;
  }
  
  h2:before {
    content: '';
    position: absolute;
    top: 21px;
    left: -105px;
    width: 86px;
    height: 2px;
    background-color: white;
  }
  
  .owl-carousel {
    height: 500px;
    background-color: white;
    padding: 150px 50px 45px 50px;
  }
  
  .owl-nav {
    display: block !important;
  }
  
  .date {
    color: #87888a;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #87888a;
    
    img {
      display: inline-block !important;
      width: auto !important;
      margin-right: 5px;
      margin-bottom: 4px;
    }
  }
  
  h3 {
    font-size: 19px;
    text-transform: uppercase;
    margin-bottom: 20px;
    height: 40px;
    overflow: hidden;
  }
  
  .content {
    line-height: 21px;
    max-height: 105px;
    overflow: hidden;
    
    img {
      display: none;
    }
    
    p {
      margin-bottom: 0;
    }
  }
  
  .button {
    margin: 15px 0 20px 0;
  }
  
  .owl-prev {
    margin-right: 10px;
  }
  
  .owl-dots {
    display: none;
  }
}


/* Single post */
body.single-post {
  article.post figure,
  article.post img {
    max-width: 100%;
  }
  
  .prev-next-projects {
    margin: 25px 0;
    
    & > div {
      padding: 0;
    }
    
    a {
      display: flex;
      align-items: center;
    }
    
    a,
    svg {
      color: #000000;
      opacity: 0.8;
    }
    
    svg {
      width: 20px;
      height: 20px;
    }
    
    a:hover,
    a:hover + svg,
    svg:hover,
    svg:hover + a {
      opacity: 1;
    }
  }
  
  a.share {
    display: inline-flex;
    align-items: center;
    
    svg {
      margin-right: 10px;
    }
  }
}


/* Portfolio */
section.portfolio {
  display: inline-block;
  position: relative;
  z-index: 2;
  width: 100%;
  text-align: center;
  margin-bottom: -100px;
  
  a {
    display: inline-block;
    padding: 15px 20px 60px 20px;
    color: #87888a !important;
    cursor: pointer;
  }
  
  a span {
    position: relative;
  }
  
  a.active {
    color: white !important;
  }
  
  a.active span:after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 16px);
    background-color: #00a0cd;
    z-index: -1;
  }
  
  #portfolio-content {
    height: 554px;
  }
  
  .item {
    position: relative;
    overflow: hidden;
    float: left;
    cursor: pointer;
  }
  
  .item a {
    width: 100%;
    height: 100%;
    padding: 0;
  }
  
  figure {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    text-align: center;
    overflow: hidden;
    position: relative;
  }
  
  figure img {
    min-width: 100%;
    min-height: 100%;
    max-width: 200%;
    max-height: 200%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
  }
  
  .item:hover .text {
    opacity: 1;
  }
  
  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 77, 112, 0.6);
    color: white;
    opacity: 0;
    transition: all 0.3s;
  }
  
  .portfolio-name {
    font-size: 19px;
    text-transform: uppercase;
  }
  
  .item-1,
  .item-2 {
    width: 277px;
    height: 277px;
  }
  
  .item-3,
  .item-4 {
    width: 277px;
    height: 554px;
  }
  
  .item-5 {
    width: 554px;
    height: 277px;
    margin-top: -277px;
  }
}


body.post-type-archive-portfolio section.portfolio {
  margin-bottom: 100px;
  display: block;
}


/* Imgtext */
section.imgtext.classic {
  position: relative;
  
  .content {
    background-color: #2b2b2b;
    padding: 68px 84px 90px 84px;
    color: white;
  }
  
  h2 {
    position: relative;
    font-size: 35px;
  }
  
  h2:before {
    content: '';
    position: absolute;
    top: 20px;
    left: -130px;
    width: 86px;
    height: 2px;
    background-color: #00a0cd;
  }
  
  a {
    display: inline-block;
    margin-top: 60px;
    padding: 10px 25px;
    font-size: 16px;
    color: white;
    background-color: #00a0cd;
    border: 1px solid #00a0cd;
    border-radius: 50px;
  }
  
  .row.first {
    position: relative;
    z-index: 1;
  }
  
  .row.second {
    position: absolute;
    z-index: 0;
    width: 100%;
    bottom: 50%;
    transform: translateY(50%);

    .image img { width: 100%; }

  }
}


section.imgtext.buttoned {
  .row {
    position: relative;
  }
  
  .image {
    background-size: cover;
    min-height: 500px;
  }
  
  .content {
    position: absolute;
    right: 170px;
    bottom: 0;
    background-color: #0076a0;
    padding: 30px;
    color: white;
    margin-left: -170px;
  }
  
  h2 {
    font-size: 19px;
    margin-bottom: 20px;
  }
}


/* Icontext */
section.icontext {
  .title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  img {
    margin-right: 17px;
    height: 30px;
  }
  
  h2 {
    display: inline-block;
    position: relative;
    font-size: 19px;
    font-weight: 400;
    line-height: 21px;
    text-transform: uppercase;
    margin-bottom: 0;
    padding-left: 17px;
    min-height: 40px;
  }
  
  h2:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 39px;
    background-color: #00a0cd;
  }
  
  .button {
    margin-top: 40px;
  }
}


/* Joboffer */
section.joboffer {
  .title-wrapper:before {
    content: '';
    position: absolute;
    top: -100px;
    left: 0;
    height: 86px;
    width: 2px;
    background: #2b2b2b;
  }
  
  .filter {
    padding: 45px 25px;
    background-color: #f7f7f7;
  }
  
  a.button,
  button {
    display: inline-block;
    font-size: 16px;
    padding: 10px 25px;
    margin-bottom: 15px;
    color: white;
    background-color: #00a0cd;
    border: 1px solid #00a0cd;
    border-radius: 50px;
  }
  
  span.cat {
    display: block;
    position: relative;
    margin-top: 20px;
    padding-bottom: 3px;
    border-bottom: 1px solid #adddeb;
  }
  
  span.cat:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 60px;
    height: 3px;
    background-color: #00a0cd;
  }
  
  span.cat-filter {
    display: block;
    font-size: 17px;
    font-weight: bold;
    color: #00a0cd;
    margin: 30px 0 20px 0;
  }
  
  input {
    display: none;
  }
  
  label {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
  }
  
  label:before {
    content: url(/wp-content/uploads/2019/04/checkbox.svg);
    display: inline-block;
    height: 18px;
    margin-right: 12px;
  }
  
  label.active:before {
    content: url(/wp-content/uploads/2019/04/checkbox_checked.svg);
  }
  
  #offer-wrapper {
    h3 {
      font-size: 19px;
      line-height: 22px;
      text-transform: uppercase;
      margin-bottom: 15px;
    }
    
    .item:not(:first-child) h3 {
      margin-top: 50px;
      padding-top: 30px;
      border-top: 1px solid #b2e2f0;
    }
    
    .text-filter {
      padding-bottom: 6px;
      margin: 24px 0 20px 0;
      color: #87888a;
      
      span {
        margin-right: 50px;
        cursor: pointer;
      }
      
      span.active {
        color: #2b2b2b;
        border-bottom: 2px solid #00a0cd;
      }
    }
    
    #text-filter {
      div:not(.active) {
        display: none;
      }
    }
    
    p {
      color: #707070;
    }
    
    a {
      display: inline-block;
      padding: 10px 25px;
      font-size: 16px;
      color: white;
      background-color: #00a0cd;
      border: 1px solid #00a0cd;
      border-radius: 50px;
      margin-top: 5px;
    }
    
    .pagination { display: block; }

    .pagination a {
      margin-top: 30px;
      padding: 8px 15px;
      background: #cccccc;
      color: #2b2b2b;
      border: 1px solid #cccccc;
      font-size: 14px;
    }

    .pagination a#prevPage { float: left; }
    .pagination a#nextPage { float: right; }
  }
}


/* Service */
section.service {
  .left {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0;
    
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 118, 160, 0.7);
    }
  }
  
  .content {
    position: relative;
    z-index: 1;
    width: 100%;
    background-color: white;
    padding: 30px 30px 30px 155px;
  }
  
  .item {
    display: flex;
    align-items: center;
    margin-top: 16px;
  }
  
  h2 {
    position: relative;
    font-size: 35px;
    margin-right: 10px;
  }
  
  h2:before {
    content: '';
    position: absolute;
    top: 20px;
    left: -120px;
    width: 90px;
    height: 2px;
    background-color: #2b2b2b;
  }
  
  .img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
  }
  
  .item img {
    margin-right: 10px;
    height: 34px;
    width: 34px;
    object-fit: contain;
  }
  
  .item a {
    color: inherit;
  }
  
  .item p {
    position: relative;
    margin-bottom: 0;
    padding-left: 17px;
  }
  
  .item p:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    width: 2px;
    height: 40px;
    background-color: #00a0cd;
  }
  
  .right {
    background-color: #004d70;
    color: white;
    padding: 90px 200px;
  }
  
  h3 {
    position: relative;
    font-size: 35px;
    margin-bottom: 22px;
  }
  
  h3:before {
    content: '';
    position: absolute;
    left: 0;
    top: -90px;
    width: 2px;
    height: 86px;
    background-color: #ffffff;
  }
}


/* Team + Carousel */
section.team,
section.carousel {
  h3 {
    position: relative;
    font-size: 35px;
    padding-left: 200px;
    margin-bottom: 50px;
  }
  
  h3:before {
    content: '';
    position: absolute;
    top: 19px;
    left: 60px;
    width: 86px;
    height: 2px;
    background-color: #2b2b2b;
  }
  
  .owl-stage-outer {
    padding: 5px 0;
  }
  
  .team-carousel .item,
  .carousel .item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 390px;
    padding: 45px 15px;
    background-color: #f7f7f7;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    
    .img-wrapper {
      width: 160px;
      height: 160px;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      overflow: hidden;
      border-radius: 50%;
      margin: 0 auto;
    }
    
    img {
      width: 100%;
      object-fit: cover;
      object-position: top center;
      height: auto;
    }
    
    span {
      display: block;
    }
    
    span.name {
      font-size: 19px;
      text-transform: uppercase;
      margin: 30px 0 10px 0;
    }
    
    span.role {
      min-height: 42px;
    }
  }
  
  .owl-prev,
  .owl-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .owl-prev {
    left: -40px;
  }
  
  .owl-next {
    right: -40px;
  }
}


section.carousel .carousel .item {
  justify-content: flex-start;
  padding: 45px 30px;
  
  .img-wrapper {
    width: 75%;
    height: 150px;
    margin-bottom: 15px;
    border-radius: 0;
  }
  
  img {
    object-fit: unset;
    object-position: inherit;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}


/* Certif */
section.certif {
  padding: 100px 0;
  
  .content {
    background-color: #f7f7f7;
    padding: 60px 40px 60px 160px;
  }
  
  h3 {
    position: relative;
    font-size: 35px;
    margin-bottom: 20px;
  }
  
  h3:before {
    content: '';
    position: absolute;
    top: 20px;
    left: -106px;
    width: 86px;
    height: 2px;
    background-color: #2b2b2b;
  }
  
  .logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  img {
    max-height: 65px;
  }
  
  img[data-toggle] {
    cursor: pointer;
  }
}


/* Contact Form */
label {
  width: 100%;
}


input:not([type="submit"]), textarea, select {
  width: 100%;
  padding: 5px 15px;
  margin-bottom: 30px;
  border: 1px solid #cccccc;
}


input[type="submit"] {
  color: white;
  cursor: pointer;
}


input[type=submit].button {
  padding: 8px 20px;
  border-radius: 50px;
  background: #3ea1ce;
  border: none;
}


input[type="file"] {
  padding: 0;
}


input[readonly="readonly"] {
  color: #777777;
}


select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  background-color: transparent;
  border-radius: 0;
  border: 1px solid #cccccc;
}


span.wpcf7-form-control-wrap.subject:after {
  content: '›';
  position: absolute;
  top: -2px;
  right: 15px;
  transform: rotate(90deg);
}


/* Search */
body.search {
  article {
    margin: 15px 0;
    padding: 15px 0;
    border-bottom: solid 1px #2b2b2b;
  }
  
  article:last-child {
    border-bottom: none;
  }
  
  article header {
    position: unset;
    width: 100%;
    h2 a {
      color: #2b2b2b;
      padding: 0;
    }
  }
}


/* Agency Single */
body.agency-template-default footer .logo,
body.page-id-345 footer .logo,
body.page-id-381 footer .logo {
  opacity: 0;
  visibility: hidden;
  height: 60px;
}


.agency-desc-right {
  max-width: 100%;
  width: 475px;
}


.agency-address {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 40px;
  
  img {
    margin-right: 10px;
  }
}


/* Portfolio Single */
body.single-portfolio {
  .portfolio-slider .owl-nav {
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
  }
  
  .img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 450px;
    vertical-align: middle;
  }
  
  .img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .prev-next-projects {
    margin: 25px 0;
    
    & > div {
      padding: 0;
    }
    
    a {
      display: flex;
      align-items: center;
    }
    
    a,
    svg {
      color: #000000;
      opacity: 0.8;
    }
    
    svg {
      width: 20px;
      height: 20px;
    }
    
    a:hover,
    a:hover + svg,
    svg:hover,
    svg:hover + a {
      opacity: 1;
    }
  }
  
  .related-projects-container {
    margin-top: 20px;
  }
  
  .related-projects__single {
    margin: 10px 0;
    
    figure {
      width: 100%;
      height: 190px;
      vertical-align: middle;
      text-align: center;
      margin: 0;
      padding: 0;
    }
    
    figure > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    
    p {
      margin-top: 10px;
      font-weight: bold;
      color: #000000;
      opacity: 0.8;
      transition: all 0.2s ease-in-out;
    }
    
    &:hover p {
      opacity: 1;
    }
  }
}


/* Footer */
footer {
  color: #87888a;
  background-color: #f7f7f7;
  padding-top: 20px;
  
  img {
    width: auto !important;
  }
  
  .left {
    padding-right: 45px;
  }
  
  .left img {
    margin: 50px 0 30px 0;
  }
  
  .logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 15px 20px 15px;
    
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 15px;
      width: calc(100% - 30px);
      height: 1px;
      background-color: #87888a;
    }
  }
  
  .address {
    font-size: 11px;
    line-height: 15px;
  }
  
  .list {
    line-height: 18px;
  }
  
  h2 {
    font-size: 17px;
    font-weight: 700;
    margin-top: 45px;
    margin-bottom: 30px;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  a:hover {
    color: #00a0cd;
    text-decoration: none;
  }
  
  a.blue {
    display: inline-block;
    padding: 7px 20px;
    font-size: 14px;
    color: white;
    background-color: #00a0cd;
    border: 1px solid #00a0cd;
    border-radius: 50px;
    transition: all 0.3s;
    
    &:hover {
      background-color: #0076a0;
      border-color: #0076a0;
    }
  }
  
  a.grey {
    display: inline-block;
    padding: 7px 20px;
    font-size: 16px;
    color: white;
    background-color: #707070;
    border: 1px solid #707070;
    border-radius: 50px;
  }
  
  a.grey:after {
    content: url(/wp-content/uploads/2019/04/fleche-bouton.svg);
    display: inline-block;
    margin-left: 10px;
    width: 22px;
    height: 8px;
  }

  a.green {
    display: inline-block;
    padding: 7px 20px;
    font-size: 16px;
    color: white;
    background-color: #c0d140;
    border: 1px solid #c0d140;
    border-radius: 50px;
  }
  
  .bottom {
    position: relative;
    padding: 14px 15px 12px 15px;
    font-size: 12px;
    
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 15px;
      width: calc(100% - 30px);
      height: 1px;
      background-color: #87888a;
    }
  }
  
  .bottom .a42 {
    position: absolute;
    right: 15px;
    top: 14px;
  }
  
  .bottom p {
    margin-bottom: 0;
  }
  
  .scrolltop {
    display: none;
    position: fixed;
    bottom: 80px;
    right: 30px;
    z-index: 500;
  }
  
  .scrolltop.active {
    display: block;
  }
  
  a.social {
    display: inline-block;
    margin-right: 11px;
  }
}


.social-media {
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 10;
  transform: translate(calc(100% - 60px), -50%);
  
  a {
    display: flex;
  }
  
  .email-btn {
    margin: -1px 0;
  }
  
  .tel-btn,
  .email-btn,
  .param-btn {
    position: relative;
    display: flex;
    align-items: center;
    right: 0;
    z-index: 10;
    padding: 17px 17px 17px 20px;
    transition: all 0.3s;
  }

  .tel-btn { background-color: #c0d008; }
  .email-btn,
  .param-btn { background-color: #00a0cd; }
  
  .tel-btn:hover,
  .email-btn:hover,
  .param-btn:hover {
    transform: translateX(calc(-100% + 60px));
  }
  
  svg path {
    fill: white;
  }
  
  span {
    color: white;
    font-size: 16px;
    margin-left: 20px;
  }
}

@media(max-width: 800px) {
  header .desk {
    display: none;
  }
  header .resp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    visibility: visible;
    opacity: 1;
  }
  header .resp .logo {
    display: inline-block;
    padding: 20px 15px;
  }
  header .resp .right-wrapper {
    display: flex;
  }
  header .resp .hamburger {
    padding-right: 15px;
  }
  header .resp .hamburger span {
    display: block;
    width: 20px;
    height: 2px;
    margin-bottom: 5px;
    background: #ffffff;
  }
  header.scroll .resp .hamburger span {
    background: #a6a7a8;
  }
  header .resp .search-img {
    position: unset;
    transform: none;
    margin-right: 15px;
  }
  header .resp + .search-wrapper {
    visibility: visible;
    opacity: 1;
    padding: 0;
  }
  header .resp .menu-wrapper {
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
    right: 0;
    transform: translate(100%, 100%);
    background-color: white;
    padding: 20px 0;
  }
  header .resp .menu-wrapper.active {
    transform: translate(0, 100%);
    height: calc(100vh - 100px);
    overflow: scroll;
  }
  header .resp .nav {
    display: block;
    text-align: center;
  }
  header .resp .nav a {
    color: #2b2b2b;
    padding: 0;
    display: inline-block;
  }
  header .resp .nav a[href] {
    color: #0076a0 !important;
  }
  header .resp li.menu-item-has-children a:hover,
  header .resp a:hover {
    color: #0076a0;
  }
  header .resp nav > ul.nav > li {
    margin-bottom: 15px;
  }
  header .resp nav > ul.nav > li > a {
    font-weight: 700;
    font-size: 18px;
  }
  header .resp ul.sub-menu {
    position: unset;
    width: 100%;
    transform: none;
  }
  ul.sub-menu {
    visibility: visible;
    opacity: 1;
  }
}


@media(max-width: 450px) {
  html {
    overflow-x: hidden;
  }
  .single-agency .left-padding {
    padding-left: 14px !important;
  }
  .single-agency .button-phone {
    margin-bottom: 20px;
  }
  .single-agency .certif > .row {
    margin: 0;
  }
  
  section.homeheader h1 {
    font-size: 40px;
    line-height: 45px;
    text-indent: 0;
  }
  section.homeheader .text,
  section.homeheader .form-wrapper {
    margin-left: 0;
  }
  
  section.skill .item {
    margin-bottom: 100px;
  }
  
  section.skill .item .ask {
    display: block;
  }
  section.skill .item + .ask {
    display: none;
  }
  
  section.sector h2 {
    text-indent: 0;
    font-size: 30px;
  }
  
  section.portfolio a {
    padding: 15px;
  }
  section.portfolio #portfolio-content {
    margin-top: 30px;
  }
  section.portfolio .item {
    width: 100%;
    margin-top: 0;
  }
  
  section.post .owl-carousel {
    height: 530px;
  }
  
  section.pagetop h1 {
    font-size: 40px;
    padding-left: 0;
  }
  section.pagetop h1:before {
    display: none;
  }
  section.pagetop #breadcrumbs {
    padding: 0 15px;
  }
  
  section.imgtext.buttoned .image {
    display: none;
  }
  section.imgtext.buttoned .content {
    position: unset;
    margin-left: 0;
  }
  
  section.imgtext.classic .content {
    padding: 30px;
  }
  section.imgtext.classic .row.second {
    display: none;
  }
  
  section.icontext {
    margin-bottom: 30px;
  }
  
  .title.left:before,
  section.certif h3:before,
  section.service h2:before {
    left: 0;
    width: 60px;
  }
  .title.left h2,
  section.certif h3,
  section.service h2 {
    padding-left: 75px;
  }
  
  section.icontext .button {
    margin-top: 15px;
    margin-bottom: 55px;
  }
  
  section.service .right {
    padding: 90px 15px 30px 15px;
  }
  section.service .content {
    padding: 30px 15px;
  }
  
  section.team h3,
  section.carousel h3 {
    padding-left: 100px;
  }
  section.team h3:before,
  section.carousel h3:before {
    left: 0;
    width: 75px;
  }
  section.team .owl-nav,
  section.carousel .owl-nav {
    display: block !important;
  }
  section.team .owl-nav .owl-prev,
  section.carousel .owl-nav .owl-prev {
    left: 5px;
  }
  section.team .owl-nav .owl-next,
  section.carousel .owl-nav .owl-next {
    right: 5px;
  }
  
  section.certif .content {
    padding: 30px 15px;
  }
  section.certif img {
    margin-bottom: 15px;
  }
  footer .left {
    padding-right: 15px;
  }
  footer .logo {
    justify-content: space-around;
    flex-wrap: wrap;
  }
  footer .logo img {
    margin-right: -20px;
  }
  footer .address,
  footer .list {
    text-align: center;
  }
  footer .scrolltop {
    bottom: 15px;
    right: 15px;
    z-index: 9999;
  }
  footer .bottom .a42 {
    position: unset;
    margin-top: 5px;
  }
  body.single-portfolio .item.im-wrapper {
    height: 300px;
  }
}

@media (max-width: 1100px) and (min-width: 500px) {
  section.imgtext.buttoned .content {
    right: 0;
    margin-left: 0;
  }
  section.service .right { padding: 90px 60px; }
}